import React from "react";
import WelcomeImg from "../../../assets/images/v2/snlc-college.webp";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UPCOMING_EVENTS } from "graphql/query/events";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import DateCard from "examples/Cards/DateCard";
const SingleSlider = styled("div")({
  width: "100%",
});
const settings = {
  dots: false,
  infinite: true,
  autoplaySpeed: 4000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function About() {
  const [offset] = React.useState(0);
  const [eventsDatas, setEventsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(UPCOMING_EVENTS, {
    variables: {
      eventsFilter: {
        orderColumn: "id",
        status: true,
        limit: 100,
        offset,
        orderDirection: "desc",
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setEventsDatas([]);
      } else {
        setEventsDatas(data.upcomingEvents.events);
      }
    }
  }, [data, loading, error]);

  return (
    <div className="about-new container">
      <div className="row header-area">
        <div className="col-md-4">
          <h2>welcome to snlc</h2>
        </div>
        <div className="col-md-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="915"
            height="2"
            viewBox="0 0 915 2"
            fill="none"
          >
            <path d="M0 1L915 0.99993" stroke="#F7E763" />
          </svg>
        </div>
      </div>
      {eventsDatas.length === 0 ? (
        <div className="row content-area">
          <div className="col-md-6">
            <img src={WelcomeImg} className="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <p>
              The college is named after the great social reformer, philosopher and saint Sree
              Narayana Guru (1854-1928). Based on the ancient directives of Universal brotherhood
              and Love, Sree Narayana Guru preached the noble message of "One Caste, One Religion,
              and One God for man." The college aims at achieving the high ideals of Sree Narayana
              Guru who exhorted us to 'strive for the welfare of all without any discrimination on
              the basis of caste, creed or religion. 'The Guru was a Karmayogi who transformed the
              idealistic Advaitha of his predecessors to a practical humanistic one by giving an
              ethical and social context to it. Imbibing the teachings of the great Guru such as
              "One Caste, One Religion, One God for man", "Liberate oneself through education",
              "Humanness marks humanity", our college strives to mould generations in order to free
              them from casteism, bigotry, sectarianism and exploitation. We consider education an
              instrument of socio-economic progress, political development and moral, social and
              intellectual awakening of each student.
            </p>
          </div>
        </div>
      ) : (
        <div className="row content-area align-items-center" id="upcoming-events">
          <div className="col-md-9">
            <div className="content">
              <img src={WelcomeImg} className="img-fluid right-side-top" alt="" />
              <p>
                The college is named after the great social reformer, philosopher and saint Sree
                Narayana Guru (1854-1928). Based on the ancient directives of Universal brotherhood
                and Love, Sree Narayana Guru preached the noble message of "One Caste, One Religion,
                and One God for man." The college aims at achieving the high ideals of Sree Narayana
                Guru who exhorted us to 'strive for the welfare of all without any discrimination on
                the basis of caste, creed or religion. 'The Guru was a Karmayogi who transformed the
                idealistic Advaitha of his predecessors to a practical humanistic one by giving an
                ethical and social context to it. Imbibing the teachings of the great Guru such as
                "One Caste, One Religion, One God for man", "Liberate oneself through education",
                "Humanness marks humanity", our college strives to mould generations in order to
                free them from casteism, bigotry, sectarianism and exploitation. We consider
                education an instrument of socio-economic progress, political development and moral,
                social and intellectual awakening of each student.
              </p>
            </div>
            <div className="svg-line">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="auto"
                height="2"
                viewBox="0 0 1061 2"
                fill="none"
              >
                <path opacity="0.2" d="M0 1L1061 0.999907" stroke="#0A0A0A" />
              </svg>
            </div>
          </div>
          <div className="col-md-3">
            <div className="upcoming-events-box">
              <div className="svg-area">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="208"
                  height="285"
                  viewBox="0 0 208 285"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M120.337 120.217L207.509 0.696289V284.898H0.816406L120.337 120.217Z"
                    fill="#35BBED"
                  />
                </svg>
              </div>
              <h2>upcoming events</h2>
              {eventsDatas.length > 1 ? (
                <Slider {...settings} className="events-slider">
                  {eventsDatas.map((events) => (
                    <>
                      <SingleSlider key={events.id}>
                        <Link to={`/event-videos/${events.id}`}>
                          <DateCard
                            image={events.thumbnail_image}
                            name={events.event_name}
                            date={events.date}
                          />
                        </Link>
                      </SingleSlider>
                    </>
                  ))}
                </Slider>
              ) : (
                eventsDatas.map((events) => (
                  <>
                    <Link to={`/event-videos/${events.id}`}>
                      <DateCard
                        image={events.thumbnail_image}
                        name={events.event_name}
                        date={events.date}
                      />
                    </Link>
                  </>
                ))
              )}

              {/* <img
              src={
                "https://d1csarkz8obe9u.cloudfront.net/themedlandingpages/tlp_hero_poster-maker-7bde0bc953786a062bbd5b6dacedf5b8.jpg"
              }
              className="img-fluid"
              alt=""
            /> */}
            </div>
          </div>
        </div>
      )}

      <div className="row header-area">
        <div className="col-md-3">
          <h2>about us</h2>
        </div>
        <div className="col-md-9">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="915"
            height="2"
            viewBox="0 0 915 2"
            fill="none"
          >
            <path d="M0 1L915 0.99993" stroke="#F7E763" />
          </svg>
        </div>
      </div>
      <div className="row content-area">
        <div className="col-md-6">
          <p>
            Sree Narayana Educational Complex is situated in the Tripunithura-Vaikom road,about
            25kms south from Ernakulam town. Poothotta is blessed with the footprints of Jagath Guru
            Sree Narayana Guru, a great sage, and social reformerwho has put ardent efforts to set
            free humanity from the bondage of social oppression,during the time when Keralites were
            segregated in to different strata ,of which the majority were labelled the
            ‘untouhcables’ as they were born in a minority caste group. The efforts by Sree Narayana
            Guru and other spiritual leaders made vibrations in the minds many people which led to
            revolutionary changes in the approach of those ‘superiors’ over the untouchables. Guru
            firmly believed that the one and only tool to lead the humanity towards social
            reformation is by providing education. In order to uplift the downtrodden sections of
            the society to the main stream,he founded several temples in Kerala and a few on the
            west coast of Karnataka. In those days the temples were governed by orthodox theocrats
            were in accessible to most of the working class people. Even though the temples were
            barred to the so called “low caste “people their offering in money and kind were always
            accepted. Guru strongly believed that such shameless exploitation of the poor by the
            caste superiors could be met by founding ‘counter temples ‘ which were open to all. read
            more
          </p>
        </div>
        <div className="col-md-6">
          {" "}
          <ReactPlayer
            controls
            muted
            url="https://snlc-files.s3.ap-south-1.amazonaws.com/videos/snlc_video.mp4"
            // light="https://d1l8ubyc91nwpi.cloudfront.net/static/media/snlc-bg-2.e1f1f05e.webp"
            className="snlc_player"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default About;

/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { Link } from "react-router-dom";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import bgFooter from "assets/images/footer-bg.png";
import logoImage from "assets/images/logo-snlc.png";

const SocialIconGrid = styled("div")({
  display: "none",
  justifyContent: "space-between",
  width: "50%",
  margin: "20px auto",

});
const SocialIcon = styled("div")({
  color: "rgba(255, 255, 255, 0.6)",
  backgroundColor: "transparent",
  padding: 1,
  textTransform: "uppercase",
  opacity: "0.8",
  fontWeight: "400",
  fontFamily: "inherit",
  border: "1.5px solid rgba(255, 255, 255, 0.6)",
  marginLeft: "5px",
  alignItems: "center",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  textAlign: "center",
  cursor: "pointer",
  display: "inline-flex",
  justifyContent: "center",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    transform: "scale(1.1)",
    border: "1.5px solid rgba(255, 255, 255, 0.8)",
    color: "rgba(255, 255, 255, 0.9)",
  },
  "& a": {
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});
const FooterBox = styled("div")({
  color: "#fff",
});

function DefaultFooter({ content }) {
  const { copyright } = content;

  return (
    <FooterBox className="footerNew">
      <MKBox
        component="footer"
        minHeight="55vh"
        width="100%"
        sx={{
          // backgroundImage: url(${bgFooter}),
          backgroundColor: "#0A0A0A",
          display: "grid",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          placeItems: "center",
          px: "0",
        }}
      >
        <Container>
          <Grid container spacing={3} sx={{ pt: "100px" }} alignItems="baseline">
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={2} pr={1}>
                  <img src={logoImage} className="img-fluid" alt="Logo" width="90" />
                </Grid>
                <Grid item xs={10} md={10}>
                  <MKTypography
                    color="white"
                    sx={{ paddingTop: "24px" }}
                    textTransform="uppercase"
                    variant="h1"
                  >
                    sree narayana law college
                  </MKTypography>

                  <MKTypography
                    className="footerAddress"
                    sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                  >
                    Puthenkavu Poothotta P.O Ernakulam, Kerala Pin: 682307.
                  </MKTypography>
                </Grid>
              </Grid>
              <SocialIconGrid>
                {" "}
                <SocialIcon>
                  <a href="">
                    {" "}
                    <FacebookOutlinedIcon />
                  </a>
                </SocialIcon>
                <SocialIcon>
                  <a href="">
                    {" "}
                    <InstagramIcon />
                  </a>
                </SocialIcon>
                <SocialIcon>
                  <a href="">
                    {" "}
                    <LinkedInIcon />
                  </a>
                </SocialIcon>
              </SocialIconGrid>
            </Grid>
            <Grid item xs={12} md={3.5} sx={{ ml: "auto", mb: 3 }} className="right-section">
              <h4>
                Quicklinks
                <hr />
              </h4>
              <Grid container spacing={1} className="link-group">
                <Grid item xs={6} md={6}>
                  <Grid item xs={12}>
                    <Link to={"/"}>Home</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/about/profile"}>About</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/downloads"}>Downloads</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/gallery"}>Gallery</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/contact-us"}>Contact</Link>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item xs={12}>
                    <Link to={"/e-learning-and-question-papers"}>Study Materials</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/webinars"}>Webinars</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/grievance"}>Grievance Redressal</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/news"}>Announcements</Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={"/iqac"}>IQAC</Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4.5} sx={{ ml: "auto", mb: 3 }} className="right-section">
              <h2 className="position-heading">contact us</h2>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  {" "}
                  <MKBox>
                    <MKTypography
                      textTransform="uppercase"
                      variant="h4"
                      sx={{ color: "rgba(255, 255, 250, 0.8)", my: "10px" }}
                    >
                      principal
                      <hr />
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Sree Narayana Law College
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Puthenkavu Poothotta P.O
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Ernakulam, Kerala Pin: 682307.
                    </MKTypography>
                    <MKTypography
                      className="cta-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", pt: "25px", fontSize: "16px" }}
                    >
                      snlcpoothotta@gmail.com
                    </MKTypography>
                    <MKTypography
                      className="cta-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", pt: "5px", fontSize: "16px" }}
                    >
                      Fax: 0484 2792377
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={6}>
                  {" "}
                  <MKBox>
                    <MKTypography
                      textTransform="uppercase"
                      variant="h4"
                      sx={{ color: "rgba(255, 255, 250, 0.8)", my: "10px" }}
                    >
                      manager
                      <hr />
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Sree Narayana Law College
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Puthenkavu Poothotta P.O
                    </MKTypography>
                    <MKTypography
                      className="contact-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", my: "5px", fontSize: "16px" }}
                    >
                      Ernakulam, Kerala Pin: 682307.
                    </MKTypography>
                    <MKTypography
                      className="cta-text"
                      sx={{ color: "rgba(255, 255, 255, 0.6)", pt: "25px", fontSize: "16px" }}
                    >
                      0484 - 2794377, +918589982030
                      <br />
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="footer-copyright">{copyright}</div>
        </Container>
      </MKBox>
    </FooterBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
